<template>
    <div class="card">
        <div class="card-body tab-content bg-white">
            <el-form
                ref="formRef"
                :model="tempData"
                class="form w-100 mb-7"
            >
                <el-form-item
                  class="fv-row mb-7 w-300px"
                  prop="year"
                  :rules="[
                    { required: true, message: 'Year is required' },
                  ]"
                >
                  <el-select name="year" v-model="tempData.year" class="form-control p-0 border-0" placeholder="Pilih Tahun" size="large">
                    <el-option
                      v-for="(item, i) in years"
                      :key="i"
                      :label="item"
                      :value="item"
                    >
                      <div class="d-flex">
                        <div class="col me-3">{{ item }}</div>
                      </div>
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  class="fv-row mb-7 w-300px"
                  prop="month"
                  :rules="[
                    { required: true, message: 'Month is required' },
                  ]"
                >
                  <el-select name="month" v-model="tempData.month" class="form-control p-0 border-0" placeholder="Pilih Bulan" size="large">
                    <el-option
                      v-for="(item, i) in months"
                      :key="i"
                      :label="item"
                      :value="i"
                    >
                      <div class="d-flex">
                        <div class="col me-3">{{ item }}</div>
                      </div>
                    </el-option>
                  </el-select>
                </el-form-item>
                <div>
                  <button
                      type="button"
                      ref="submitButton"
                       @click="onSubmit(formRef)"
                      class="btn btn-primary w-300px justify-content-end"
                      :data-kt-indicator="loadingSubmit ? 'on' : 'off'"
                  >
                      <span class="indicator-label"> Submit </span>

                      <span class="indicator-progress">
                          Please wait...
                          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                  </button>
                </div>
            </el-form>

            <div ref="tableRef" class="table-fixed-header mt-7">
                <h5 class="text-gray-700">List Produk (Bermasalah)</h5>
                <table class="table table-rounded table-row-dashed cursor-pointer table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                    <thead>
                        <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
                            <th class="w-50px">#</th>
                            <th>Nomor Pesanan</th>
                            <th>Artikel</th>
                            <th>SKU</th>
                            <th>Tanggal Pesanan</th>
                            <th class="text-end">Qty</th>
                            <th class="text-end">Harga</th>
                            <th class="text-end">Total</th>
                        </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-600">
                        <tr v-for="(item, i) in tableData" :key="i">
                            <td class="w-50px">{{ i+1 }}</td>
                            <td>{{ item.no_pesanan }}</td>
                            <td>{{ item.artikel }}</td>
                            <td>{{ item.barcode }}</td>
                            <td>{{ item.tgl_pesanan }}</td>
                            <td class="text-end">{{ formatIDR(item.qty) }}</td>
                            <td class="text-end">{{ formatIDR(item.harga) }}</td>
                            <td class="text-end">{{ formatIDR(item.qty * item.harga) }}</td>
                        </tr>
                    </tbody>
                    <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </table>
            </div>
        </div>
    </div>

</template>

<style scoped lang="scss">
    .accordion {
        .accordion-item {
            //border: none !important;

            button {
                padding: 1.5rem 2rem;
                border-radius: 0 !important;
            }
        }
    }
</style>

<script>
import { defineComponent, onMounted, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, FormInstance, FormRules } from "vee-validate";
import InnerImageZoom from 'vue-inner-image-zoom';
import { InfoFilled } from '@element-plus/icons-vue'
import { ElLoading } from 'element-plus'
import { ElMessage, ElMessageBox } from 'element-plus'
import fileDownload from 'js-file-download';
import Axios from 'axios'
import moment from 'moment'
import { useRoute, useRouter } from 'vue-router'
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";

export default defineComponent({
    components: { 
        //Datatable,
        //Field,
        //Form,
        //ErrorMessage,
        //'inner-image-zoom': InnerImageZoom,
    },
    setup() {
        const store = useStore()
        const route = useRoute()
        const router = useRouter()
        
        const img_url_mockup = ref(process.env.VUE_APP_IMAGE_MOCKUPS)
        const img_url_desain= ref(process.env.VUE_APP_IMAGE_DESAINS)

        const loadingSubmit = ref(false)
        const loadingTable = ref(false);
        const tableRef = ref(null)

        const formRef = ref(null);
        const tempData = reactive({
            year: null,
            month: null,
            commit: true,
        })

        const years = ref([])
        const months = ref(["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]);


        const tableData = reactive([]);

        const dateShortcuts = [
          { 
            text: 'Last week',
            value: () => {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              return [start, end]
            },
          },
          {
            text: 'Last month',
            value: () => {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              return [start, end]
            },
          },
          {
            text: 'Last 3 months',
            value: () => {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              return [start, end]
            },
          },
        ]

        const getYears = (startYear) => {
            var currentYear = new Date().getFullYear(), arr = [];
            startYear = startYear || 1980;  
            while ( startYear <= currentYear ) {
                arr.push(startYear++);
            }   

            years.value = arr.reverse();
        }

        getYears(2019-10);

        const onSubmit = async (formEl) => {
            try {
                if (!formEl) return

                await formEl.validate( async(valid, fields) => {
                    loadingSubmit.value = true;

                    if (valid) {
                        await tableData.splice(0)

                        let endpoint = 'trans_packing_list';

                        let setMonth = parseInt(tempData.month)+1; // Set to real month
                        
                        await ApiService.post(endpoint, { year: tempData.year, month: setMonth, commit: tempData.commit })
                        .then((response) => {
                            Swal.fire({
                              title: "Success",
                              text: response.data.messages,
                              icon: "success",
                              buttonsStyling: false,
                              showConfirmButton: false,
                            });

                            Object.assign(tableData, response.data.arrayNotInProduct)

                            // Set persisten data to local storage
                            localStorage.setItem("packinglist_data", JSON.stringify(tableData))
                        })
                        .catch((error) => {
                            if(error.response) {
                                Swal.fire({
                                  title: "Error",
                                  text: error.response.data.messages.error,
                                  icon: "error",
                                  buttonsStyling: false,
                                  showConfirmButton: false,
                                });
                            }
                        });

                    } else {
                        throw new Error('Please check form before submit.')
                    }

                    loadingSubmit.value = false
                })

            } catch (e) {
                Swal.fire({
                  title: "Error",
                  text: e.message,
                  icon: "error",
                  buttonsStyling: false,
                  showConfirmButton: false,
                });
            }
        }

        if(localStorage.getItem("packinglist_data") && JSON.parse(localStorage.getItem("packinglist_data")).length > 0) {
            let d = JSON.parse(localStorage.getItem("packinglist_data"))
            Object.assign(tableData, d)
        }

        onMounted(async() => {
            setCurrentPageBreadcrumbs(route.meta.title, [route.meta.title]);
        })

        return {
            formatIDR, moment,
            loadingSubmit, loadingTable, 
            tableRef, img_url_mockup, img_url_desain, tempData,
            formRef, onSubmit,
            tableData, years, months,
        }
    }
})
</script>